.topContainer {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #13c3b2;
    text-align: center;
    padding: 20px 0;
    position: relative;
}

.logo {
    width: 80px;
}

.title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    color: white;
    font-size: 25px;
    font-weight: normal;
}

.form {
    width: 50%;
    text-align: center;
    margin: 30px auto;
    font-weight: normal;
}

.formButton {
    background-color: #13c3b2;
    color: white;
    width: 180px;
    border-radius: 5px;
    padding: 3px;
}

.agreement {
    width: 100%;
    height: 40vh;
    background-color: #647280;
    margin: 25px 0;
}

.sigPad {
    background-color: #647280;
    width: 60%;
    height: 20vh;
    margin: 25px auto;
}